<template>
  <div>
    <template v-if="!detailVisible">
      <div class="card" style="display: flex">
        <el-button
            type="primary"
            plain
            @click="handleExportAll"
            style="height: 40px"
            :loading="exportLoading"
            icon="el-icon-upload2"
            >导出</el-button
          >
          <el-button
            type="primary"
            style="height: 40px"
            @click="handlePrint"
            :loading="printAllLoading"
            >打印</el-button
          >
          <div class="line"></div>
          <el-form :model="form" class="form" style="flex: 1" :inline="true">
            <el-form-item label="单据日期">
              <el-date-picker
                v-model="form.create_time"
                type="daterange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="门店">
              <el-select
                clearable=""
                v-model="form.hierarchy_id"
                filterable
                :disabled="userInfo.hierarchy_type_id === 40"
                collapse-tags
              >
                <template v-for="item in merchantList">
                  <el-option
                    :value="item.hierarchy_id"
                    :label="item.hierarchy_name"
                    :key="item.hierarchy_id"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btns">
            <el-button @click="handleSearch" type="primary">查询</el-button>
            <el-button @click="handleReset">重置</el-button>
          </div>
      </div>
      <div class="total">
          <div class="item">
            <div class="money">{{tableData.total.income_pay_total}}</div>
            <div>总盈收(元)</div>
          </div>
          <div class="item">
            <div class="block">
              <div class="money">{{tableData.total.income}}</div>
              <div>收入(元)</div>
            </div>
            <div class="line"></div>
            <div class="block">
              <div class="money">{{tableData.total.pay}}</div>
              <div>支出(元)</div>
            </div>
          </div>
      </div>
      <div class="table">
          <el-table
            border
            :data="tableData.list"
            stripe
            v-loading="loading"
            height="580"
            @row-dblclick="handleDalclick"
            v-if="columnList.length"
          >
            <template v-for="(item, index) in columnList">
              <el-table-column
                align="center"
                :key="index"
                :prop="item.field_alias"
                :label="item.field_text"
                :width="item.t_width"
                :filters="item.filed_status"
              >
                <template slot-scope="scope">
                  <template>
                    {{
                      scope.row[item.field_alias]
                        ? scope.row[item.field_alias]
                        : "--"
                    }}
                  </template>
                </template>
              </el-table-column>
            </template>
            <el-table-column align="center" field_alias="operation" label="操作"  width="180">
              <template slot-scope="scope">
                <el-button type="text" @click="toDetail(scope.row)" v-if="scope.row.stream_bill_type">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <!-- <div class='pagina'>
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout='total, sizes, prev, pager, next, jumper'
          :total='tableData.total'
          :page-size='limit'
          :page-sizes="$store.state.pageSizes"
          @current-change='handleCurrentChange'
        ></el-pagination>
      </div> -->
    </template>
    <Detail v-if="detailVisible" @onBack="detailVisible = false" :row="currRow" :outsideForm="form" />
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  getMerchantListReq,
} from "@/api/goods/historyInv/index";
import { getTotalColumnsReq, getSunndrySummaryReq, exportSummaryReq } from '@/api/statistics/finance/summary';
import LocalPrint from "@/components/localPrint/Index.vue";
import storage from "good-storage";
import Detail from './Detail.vue';

export default {
  data() {
    return {
      currRow: {},
      localPrintVisible: false,
      title: '',
      printData: {},
      printRows: [],
      detailVisible: false,
      loading: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      tableData: {
        list: [],
        total: {},
      },
      columnList: [],
      userInfo: {},
      printAllLoading: false,
      exportLoading: false,
      form: {},
      merchantList: [], // 门店列表
    };
  },
  components: {
    Detail,
    LocalPrint,
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getMerchantList();
  },
  methods: {
    toDetail(row) {
      // this.currRow = row;
      // this.detailVisible = true;
      if (!row.stream_bill_type) {
        return;
      }
      this.$router.push({
        name: 'summaryDetail',
        query: {
          t: Date.now(),
        },
        params: {
          row,
          form: this.form,
        },
      })
    },
    // 列表数据
    getList() {
      this.loading = true;
      getSunndrySummaryReq({
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.columnList = res.data.head.map((item) => {
              return { ...item, width: item.t_width / 10 }
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false
        });
    },
    // 获取表头
    getTotalColumns() {
      getTotalColumnsReq({
        function_code: 'streamSummary',
      })
        .then((res) => {
          if (res.code === 1) {
            this.columnList = res.data.map((item) => {
              return { ...item, width: item.t_width / 10 }
            });
            this.getList();
          }
        });
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    handleDalclick(row) {
      this.toDetail(row);
    },
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset() {
      this.page = 1;
      this.form = {
        hierarchy_id: this.userInfo.hierarchy_type_id == 40 ? this.merchantList[0].hierarchy_id : '',
      };
      this.getList();
    },
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          this.form = {
            ...this.form,
            hierarchy_id: this.userInfo.hierarchy_type_id == 40 ? res.data[0].hierarchy_id : '',
          };
          // this.getTotalColumns();
          this.getList();
        }
      });
    },
    handleExportAll() {
      this.exportLoading = true;
      exportSummaryReq({
        ...this.form,
        export: 1,
        function_code: 'streamSummary',
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `收支汇总表.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handlePrint() {
      if (!this.tableData.list.length) {
        this.$message.warning('没有可打印的数据');
        return;
      }
      this.title = '收支汇总表';
      this.printRows = this.columnList;
      this.printData = this.tableData;
      this.localPrintVisible = true;
    },
  },
}
</script>

<style lang="less" scoped>
.form {
  display: flex;
  /deep/ .el-form-item {
    margin-bottom: 0px !important;
  }
}
.total {
  display: flex;
  background: white;
  margin-top: 10px;
  padding: 20px;
  .item {
    height: 90px;
    padding: 0 60px;
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    text-align: center;
    font-size: 14px;
    .money {
      font-size: 28px;
      margin-top: 15px;
    }
  }
  .item:nth-child(1) {
    background: linear-gradient(#fd7e5d 0%, #ff628a 100%);
    margin-right: 20px;
  }
  .item:nth-child(2) {
    display: flex;
    background: linear-gradient(#5aabfe 0%, #597bf7 100%);
    .block {
      padding: 0 30px;
    }
    .line {
      width: 1px;
      height: 50px;
      background: white;
      margin-top: 20px;
    }
  }
}
</style>
